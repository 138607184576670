import Big from "big.js";
import { filter, zipObj, map, fromPairs } from "ramda";
import { ReduxActions } from "./actions";
import {
  AssetsType,
  initialState,
  PortfoliosType,
  ReduxStateType,
} from "./index";
import {
  calculateAssetsTotalAmount,
  calculateAssetsTotalPercentage,
  generatePortfolioName,
} from "../utils";

export const reducer = (
  state = initialState,
  action: ReduxActions
): ReduxStateType => {
  switch (action.type) {
    case "SET_STORE_FROM_SHARED_LINK":
      return {
        ...state,
        ...action.payload,
      };
    case "SHOW_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          language: action.payload,
        },
      };
    case "SET_CURRENCY":
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          currency: {
            ...state.globalConfigs.currency,
            currentCurrency: action.payload,
          },
        },
      };
    case "SET_PORTFOLIO_SELECTED_ID":
      return {
        ...state,
        currentPortfolioId: action.payload,
      };
    case "ADD_OR_UPDATE_ASSET_TO_PORTFOLIO": {
      const newState = { ...state };
      switch (action.rule) {
        case "RULE_ADD_ASSET_INVESTMENT_VALUE": {
          newState.portfolios[action.portfolioId].assets = {
            ...state.portfolios[action.portfolioId].assets,
            [`${String(action.payload.assetType)}❤❤❤${String(
              action.payload.identifier
            )}`]: {
              asset: action.payload,
              percentage: action.percentage,
              amount: action.amount,
              isLocked: action.isLocked,
            },
          };

          const unlockedAssets = filter(
            (asset) => asset.isLocked === false,
            newState.portfolios[action.portfolioId].assets
          );

          const lockedAssets = filter(
            (asset) => asset.isLocked === true,
            newState.portfolios[action.portfolioId].assets
          );

          const totalInvestmentValue = action.isLocked
            ? Big(100)
                .div(action.percentage || 1) // Bater regra de percentual
                .times(action.amount || 0)
                .round(16)
                .toNumber()
            : calculateAssetsTotalAmount(
                newState.portfolios[action.portfolioId].assets
              );
          newState.portfolios[action.portfolioId].totalAmount =
            totalInvestmentValue;

          if (totalInvestmentValue === 0) {
            Object.keys(newState.portfolios[action.portfolioId].assets).forEach(
              (assetKey) => {
                newState.portfolios[action.portfolioId].assets[
                  assetKey
                ].percentage = 0;
              }
            );
          } else {
            Object.keys(lockedAssets).forEach((assetKey) => {
              newState.portfolios[action.portfolioId].assets[assetKey].amount =
                Big(
                  newState.portfolios[action.portfolioId].assets[assetKey]
                    .percentage || 0
                )
                  .times(totalInvestmentValue)
                  .div(100)
                  .toNumber();
            });
            Object.keys(unlockedAssets).forEach((assetKey) => {
              newState.portfolios[action.portfolioId].assets[
                assetKey
              ].percentage = Big(
                newState.portfolios[action.portfolioId].assets[assetKey]
                  .amount || 0
              )
                .div(totalInvestmentValue)
                .times(100)
                .toNumber();
            });
          }
          break;
        }
        case "RULE_EDIT_ASSET_PERCENTAGE": {
          newState.portfolios[action.portfolioId].assets = {
            ...state.portfolios[action.portfolioId].assets,
            [`${String(action.payload.assetType)}❤❤❤${String(
              action.payload.identifier
            )}`]: {
              asset: action.payload,
              percentage: action.percentage || 0,
              amount:
                action.percentage === null
                  ? 0
                  : Big(action.percentage)
                      .times(state.portfolios[action.portfolioId].totalAmount)
                      .div(100)
                      .toNumber(),
              isLocked: action.isLocked,
            },
          };
          break;
        }
        default:
      }

      const { orderedPortfolioCategories } = state.globalConfigs;

      const assetsKeys = Object.keys(
        newState.portfolios[action.portfolioId].assets
      );

      const orderedAssets =
        (fromPairs(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          orderedPortfolioCategories
            .map((category) =>
              assetsKeys.map((assetKey) =>
                newState.portfolios[action.portfolioId].assets[assetKey].asset
                  .portfolioCategory === category
                  ? [
                      assetKey,
                      newState.portfolios[action.portfolioId].assets[assetKey],
                    ]
                  : []
              )
            )
            .flat()
            .filter((asset) => asset.length !== 0)
        ) as AssetsType) || {};

      newState.portfolios[action.portfolioId].assets = orderedAssets;

      return newState;
    }
    case "REMOVE_ASSET_FROM_PORTFOLIO": {
      const newAssets = { ...state.portfolios[action.portfolioId].assets };
      delete newAssets[action.payload];
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.portfolioId]: {
            ...state.portfolios[action.portfolioId],
            assets: newAssets,
          },
        },
      };
    }
    case "UPDATE_ASSET_LOCK_IN_PORTFOLIO": {
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.portfolioId]: {
            ...state.portfolios[action.portfolioId],
            assets: {
              ...state.portfolios[action.portfolioId].assets,
              [action.payload]: {
                ...state.portfolios[action.portfolioId].assets[action.payload],
                isLocked: action.locked,
              },
            },
          },
        },
      };
    }
    case "RENAME_PORTFOLIO":
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.portfolioId]: {
            ...state.portfolios[action.portfolioId],
            name: action.payload,
          },
        },
      };
    case "UPDATE_PORTFOLIO_TOTAL": {
      const newState = { ...state };
      Object.keys(newState.portfolios[action.portfolioId].assets).forEach(
        (assetKey) => {
          newState.portfolios[action.portfolioId].assets[assetKey].amount = Big(
            newState.portfolios[action.portfolioId].assets[assetKey]
              .percentage || 0
          )
            .div(100)
            .times(action.payload)
            .toNumber();
        }
      );
      newState.portfolios[action.portfolioId].totalAmount = action.payload;
      return newState;
    }
    case "SET_PERIOD":
      return {
        ...state,
        period: action.payload,
      };
    case "ADD_PORTFOLIO":
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [Object.keys(state.portfolios).length]: {
            name: generatePortfolioName({ ...state.portfolios }),
            totalAmount: 0,
            identifier: "",
            assetType: "PORTFOLIO",
            assets: {},
          },
        },
      };
    case "CLONE_PORTFOLIO": {
      const clonedPortfolio = {
        ...state.portfolios[action.cloneFromId],
        identifier: action.remoteId,
        name: `Clone - ${state.portfolios[action.cloneFromId].name}`,
      };

      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [Object.keys(state.portfolios).length]: { ...clonedPortfolio },
        },
      };
    }
    case "ADD_BENCHMARK":
      return {
        ...state,
        benchmarks: {
          ...state.benchmarks,
          [`${String(action.payload.assetType)}❤❤❤${String(
            action.payload.identifier
          )}`]: {
            asset: { ...action.payload },
          },
        },
      };
    case "REMOVE_BENCHMARK": {
      const newBenchmarks = { ...state.benchmarks };
      delete newBenchmarks[action.payload];

      return {
        ...state,
        benchmarks: {
          ...newBenchmarks,
        },
      };
    }
    case "BALANCE_PORTFOLIO": {
      const newAssets = { ...state.portfolios[action.portfolioId].assets };
      const unlockedAssets = filter(
        (asset) => asset.isLocked === false,
        newAssets
      );
      const lockedAssets = filter(
        (asset) => asset.isLocked === true,
        newAssets
      );

      const lockedAssetsPercentage = calculateAssetsTotalPercentage(
        lockedAssets,
        16
      );
      const balancedAssetPercentages =
        Object.keys(unlockedAssets).length === 0 ||
        Big(lockedAssetsPercentage).gt(100)
          ? 0
          : Big(100)
              .minus(lockedAssetsPercentage)
              .div(Object.keys(unlockedAssets).length)
              .toNumber();

      Object.keys(unlockedAssets).forEach((assetKey) => {
        unlockedAssets[assetKey].percentage = balancedAssetPercentages;
        unlockedAssets[assetKey].amount = Big(balancedAssetPercentages)
          .times(state.portfolios[action.portfolioId].totalAmount)
          .div(100)
          .toNumber();
      });
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.portfolioId]: {
            ...state.portfolios[action.portfolioId],
            assets: { ...lockedAssets, ...unlockedAssets },
          },
        },
      };
    }
    case "ADD_OR_UPDATE_PORTFOLIO_REMOTE_ID": {
      const remoteId =
        action.remotePortfolioId === "failed"
          ? state.portfolios[action.portfolioId].identifier
          : action.remotePortfolioId;

      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.portfolioId]: {
            ...state.portfolios[action.portfolioId],
            identifier: remoteId,
          },
        },
      };
    }
    case "SET_APPLICATION_AMOUNT": {
      return {
        ...state,
        applicationAmount: action.payload,
      };
    }
    case "SET_SHOW_SIDE_EDITOR": {
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          showSideEditor: action.payload,
        },
      };
    }
    case "UPDATE_PORTFOLIO_BEING_EDITED": {
      const openSidePanel = action.payload !== null;
      return {
        ...state,
        currentPortfolioId:
          action.payload === null ? state.currentPortfolioId : action.payload,
        globalConfigs: {
          ...state.globalConfigs,
          portfolioBeingEdited: action.payload,
          showSideEditor: openSidePanel,
        },
      };
    }
    case "SET_SHOW_ASSETS": {
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          showAssets: action.payload,
        },
      };
    }
    case "REMOVE_PORTFOLIO": {
      const portfoliosArrayed = Object.keys(state.portfolios).map(
        (portfolioKey) => state.portfolios[Number(portfolioKey)]
      );
      portfoliosArrayed.splice(action.payload, 1);

      const isPortfoliosEmpty = portfoliosArrayed.length === 0;
      const initialPortfolio: PortfoliosType = {
        0: {
          name: generatePortfolioName({}),
          totalAmount: 0,
          identifier: "",
          assetType: "PORTFOLIO",
          assets: {},
        },
      };
      const updatedPortfolios = zipObj(
        portfoliosArrayed.map((_portfolio, index) => String(index)),
        portfoliosArrayed
      );

      return {
        ...state,
        currentPortfolioId: 0,
        portfolios: isPortfoliosEmpty
          ? { ...initialPortfolio }
          : {
              ...updatedPortfolios,
            },
      };
    }
    case "SET_CUSTOM_DATE_RANGE": {
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          customDateRange: action.payload,
        },
      };
    }
    case "SET_FETCHED_DATE_RANGE": {
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          fetchedDateRange: action.payload,
        },
      };
    }
    case "SET_WINDOW": {
      return {
        ...state,
        windowValue: action.payload,
      };
    }
    case "SET_CONSOLIDATED": {
      return {
        ...state,
        consolidated: action.payload,
      };
    }
    case "SET_COMPETENCE": {
      return {
        ...state,
        competence: action.payload,
      };
    }
    case "SET_SHARE_ERROR":
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          showShareError: action.payload,
        },
      };
    case "SET_LOADING_FROM_LINK":
      return {
        ...state,
        sharedLinkTasks: {
          ...state.sharedLinkTasks,
          [action.task]: action.payload,
        },
      };
    case "SET_AVAILABLE_COMPETENCES":
      return {
        ...state,
        globalConfigs: {
          ...state.globalConfigs,
          availableCompetences: action.payload,
        },
      };
    case "ADD_CUSTOM_BENCHMARK_ASSET":
      return {
        ...state,
        searchableCustomBenchmarks: [
          ...state.searchableCustomBenchmarks,
          action.payload,
        ],
      };
    case "SET_SHOW_CUSTOM_BENCHMARKS":
      return {
        ...state,
        showAllSearchableCustomBenchmarks: action.payload,
      };

    case "UNLOCK_ALL_PORTFOLIO_ASSETS":
      return {
        ...state,
        portfolios: {
          ...state.portfolios,
          [action.payload]: {
            ...state.portfolios[action.payload],
            assets: {
              ...map(
                (asset) => ({ ...asset, isLocked: false }),
                state.portfolios[action.payload].assets
              ),
            },
          },
        },
      };
    default:
      return state;
  }
};
