import { Accordion, AccordionDetails, Slide } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMeasure } from "react-use";
import { useTheme } from "styled-components";
import { PortfolioPlaceHolder } from "../../components";
import { SExpandMoreIcon } from "../../components/PortfolioPlaceHolder/styles";
import {
  BenchmarksSelector,
  PeriodsSelector,
  PortfolioEditor,
  PortfoliosSelector,
  ApplicationAmount,
  RentabilityChart,
  RentabilityTable,
  QuantumBanner,
  LocaleConfigsSelector,
  VolatilityTable,
  RiskChart,
  LiquidityCharts,
  WalletCharts,
  ShareButtons,
} from "../../components/shared";
import {
  useAllPortfolios,
  useBenchmarks,
  useCurrentPortfolio,
  useSideEditor,
} from "../../store";
import { scrollTo } from "../../utils";
import {
  SAccordionSummary,
  SAccordionTitle,
  SChartsArea,
  SGridButtonsRow,
  SGridColumnLeft,
  SGridColumnRight,
  SHeaderRow,
  STitleAndButtonsRow,
  SGridContainer,
  SGridLocalesFloatingMenu,
  SMessage,
  SPaddedLine,
  STab,
  STabs,
  STitle,
  SUnpaddedLine,
  SChartRow1,
  SChartRow2,
} from "./styles";

export const PortfolioAnalysis: React.FC = () => {
  const { t } = useTranslation();
  const { selectedPortfolio } = useCurrentPortfolio();
  const [tabvValue, setTabValue] = useState(0);
  const { isSideEditorActive } = useSideEditor();
  const theme = useTheme();
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rentabilityRef = useRef<HTMLDivElement>(null);
  const riskRef = useRef<HTMLDivElement>(null);
  const liquidityRef = useRef<HTMLDivElement>(null);
  const walletRef = useRef<HTMLDivElement>(null);
  const { allPortfolios } = useAllPortfolios();
  const navigate = useNavigate();
  const benchmarksSelectorRef = useRef<HTMLSpanElement>(null);
  const periodsSelectorRef = useRef<HTMLSpanElement>(null);
  const [buttonsRowRef, buttonsRowMeasure] = useMeasure<HTMLDivElement>();
  const [buttonsOrder, setButtonsOrder] = useState<string[]>(["2", "3"]);
  const { selectedBenchmarks } = useBenchmarks();
  const [benchmarksOthers, setBenchmarksOthers] = useState(false);

  const isSinglePortfolioMode = theme.portfolios.singlePortfolioMode;

  useEffect(() => {
    const isOnlyOnePortfolio = Object.keys(allPortfolios).length === 1;
    const isFirstPortfolioNotIdentified =
      allPortfolios[Number(Object.keys(allPortfolios)[0])].identifier === "";

    if (isOnlyOnePortfolio && isFirstPortfolioNotIdentified)
      navigate("../", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabSelection = (
    _event: React.SyntheticEvent,
    newTabValue: number
  ): void => {
    setTabValue(newTabValue);
    switch (newTabValue) {
      case 0:
        scrollTo(leftColumnRef, rentabilityRef, 100);
        break;
      case 1:
        scrollTo(leftColumnRef, riskRef, 12);
        break;
      case 2:
        scrollTo(leftColumnRef, liquidityRef, 12);
        break;
      case 3:
        scrollTo(leftColumnRef, walletRef, 12);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const benchmarksHeight = benchmarksSelectorRef?.current?.offsetTop;
    const periodsHeight = periodsSelectorRef?.current?.offsetTop;

    if (benchmarksHeight === periodsHeight) {
      setButtonsOrder(["2", "3"]);
    } else {
      setButtonsOrder(["3", "2"]);
    }
  }, [
    benchmarksSelectorRef?.current?.offsetTop,
    periodsSelectorRef?.current?.offsetTop,
    buttonsRowMeasure,
    selectedBenchmarks,
    benchmarksOthers,
  ]);

  return (
    <SGridContainer>
      <SGridColumnLeft ref={leftColumnRef}>
        <SPaddedLine>
          <SHeaderRow>
            <STitleAndButtonsRow>
              <STitle>
                {isSinglePortfolioMode
                  ? t("analysisComparePortfoliosSingleMode")
                  : t("analysisComparePortfolios")}
              </STitle>
              <ShareButtons />
            </STitleAndButtonsRow>
            <SMessage>
              {isSinglePortfolioMode
                ? t("analysisComparePortfoliosMessageSingleMode")
                : t("analysisComparePortfoliosMessage")}
            </SMessage>
          </SHeaderRow>
          <PortfoliosSelector />
          <SGridButtonsRow ref={buttonsRowRef}>
            <ApplicationAmount />
            <span
              ref={benchmarksSelectorRef}
              style={{ order: buttonsOrder[0] }}
            >
              <BenchmarksSelector setBenchmarksOthers={setBenchmarksOthers} />
            </span>
            <span ref={periodsSelectorRef} style={{ order: buttonsOrder[1] }}>
              <PeriodsSelector />
            </span>
          </SGridButtonsRow>
        </SPaddedLine>
        <SUnpaddedLine>
          <STabs
            value={tabvValue}
            onChange={handleTabSelection}
            scrollButtons={false}
            variant="fullWidth"
          >
            <STab value={0} label={t("rentability.tab")} />
            <STab value={1} label={t("risk.tab")} />
            <STab value={2} label={t("liquidity.tab")} />
            <STab value={3} label={t("wallet.tab")} />
          </STabs>
        </SUnpaddedLine>
        <SChartsArea>
          <SChartRow1>
            <Accordion defaultExpanded ref={rentabilityRef}>
              <SAccordionSummary expandIcon={<SExpandMoreIcon />}>
                <SAccordionTitle>{t("rentability.tab")}</SAccordionTitle>
              </SAccordionSummary>
              <AccordionDetails>
                <RentabilityChart />
                <RentabilityTable />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded ref={riskRef}>
              <SAccordionSummary expandIcon={<SExpandMoreIcon />}>
                <SAccordionTitle>{t("risk.tab")}</SAccordionTitle>
              </SAccordionSummary>
              <AccordionDetails>
                <RiskChart />
                <VolatilityTable />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded ref={liquidityRef}>
              <SAccordionSummary expandIcon={<SExpandMoreIcon />}>
                <SAccordionTitle>{t("liquidity.tab")}</SAccordionTitle>
              </SAccordionSummary>
              <AccordionDetails>
                <LiquidityCharts />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded ref={walletRef}>
              <SAccordionSummary expandIcon={<SExpandMoreIcon />}>
                <SAccordionTitle>{t("wallet.tab")}</SAccordionTitle>
              </SAccordionSummary>
              <AccordionDetails>
                <WalletCharts />
              </AccordionDetails>
            </Accordion>
          </SChartRow1>
          <SChartRow2>
            <QuantumBanner />
          </SChartRow2>
        </SChartsArea>
        {theme.localeSettings.showSettingsGearButton &&
          theme.localeSettings.showInsideLeftColumn && (
            <SGridLocalesFloatingMenu
              sx={{
                right: isSideEditorActive ? 405 : 0,
                top: 70,
              }}
            >
              <LocaleConfigsSelector />
            </SGridLocalesFloatingMenu>
          )}
      </SGridColumnLeft>
      <Slide
        direction="left"
        in={isSideEditorActive}
        mountOnEnter
        unmountOnExit
      >
        <SGridColumnRight>
          {selectedPortfolio === null ? (
            <PortfolioPlaceHolder />
          ) : (
            <PortfolioEditor />
          )}
        </SGridColumnRight>
      </Slide>
    </SGridContainer>
  );
};
