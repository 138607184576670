import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  SAppBar,
  SLink,
  SLogoImg,
  SMenuItem,
  SMenuItems,
  SToolbar,
} from "./styles";
import { LocaleConfigsSelector } from "../shared";

export const NavBar: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("portfolio");

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isSinglePortfolioMode = theme.portfolios.singlePortfolioMode;

  const handleSelectTab = (tabId: string): void => {
    setSelectedTab(tabId);
    switch (tabId) {
      case "portfolio":
        navigate("/");
        break;

      case "asset":
        navigate("/asset");
        break;

      case "monitoring":
        navigate("./monitoring");
        break;
      default:
        break;
    }
  };

  const setInitialTab = (pathname: string): void => {
    switch (pathname) {
      case "/analysis":
      case "/":
        setSelectedTab("portfolio");
        break;
      case "/asset":
        setSelectedTab("asset");
        break;
      case "/monitoring":
        setSelectedTab("monitoring");
        break;
      default:
        break;
    }
  };

  useEffect(
    () => setInitialTab(location?.pathname),
    [location.pathname, setSelectedTab]
  );

  return (
    <SAppBar position="sticky">
      <SToolbar>
        {theme.logo.show === true && (
          <SLogoImg
            src={theme.logo.img}
            height={theme.logo.height}
            alt="logo"
          />
        )}
        <SMenuItems container spacing={0}>
          <Grid item>
            <SMenuItem
              id="nav-button"
              onClick={() => handleSelectTab("portfolio")}
              $selected={selectedTab === "portfolio"}
            >
              <SLink to="/">
                {isSinglePortfolioMode
                  ? t("navComparePortfolioSingleMode")
                  : t("navComparePortfolio")}
              </SLink>
            </SMenuItem>
          </Grid>
          <Grid item>
            <SMenuItem
              color="inherit"
              onClick={() => handleSelectTab("asset")}
              $selected={selectedTab === "asset"}
            >
              {t("navCompareAssets")}
            </SMenuItem>
          </Grid>
          {/*    <Grid item>
            <SMenuItem
              color="inherit"
              onClick={() => handleSelectTab("monitoring")}
              $selected={selectedTab === "monitoring"}
            >
              {t("navMonitoring")}
            </SMenuItem>
            </Grid>
       */}
        </SMenuItems>
        {theme.localeSettings.showSettingsGearButton &&
          !theme.localeSettings.showInsideLeftColumn && (
            <LocaleConfigsSelector />
          )}
      </SToolbar>
    </SAppBar>
  );
};
