import type * as HighchartsTypes from "highcharts";
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  LineSeries,
  Legend,
  Subtitle,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { indexOf, isEmpty, isNil, last, mergeAll } from "ramda";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import React, { useCallback, useEffect, useState } from "react";
import { useMeasure } from "react-use";
import {
  SCircularProgress,
  SFloatButton,
  SGridContainer,
  SFloatButtonIcon,
  SFloatButtonText,
  SGridLoading,
  SNoResultsWarning,
} from "./styles";
import {
  getExportingConfig,
  chartNumber,
  useAssetColor,
  useBenchmarkColor,
} from "../../../utils";
import {
  useAllPortfolios,
  useBenchmarks,
  useInitialApplication,
  usePeriod,
  AssetsType,
  useShowAssets,
  useCurrency,
  useCustomDateRange,
} from "../../../store";
import {
  useFetchPortfolioRentability,
  useFetchRentability,
} from "../../../api";

HighchartsExporting(Highcharts);

export const RentabilityChart: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { selectedPeriod } = usePeriod();
  const { selectedCustomDateRange } = useCustomDateRange();
  const { selectedBenchmarks } = useBenchmarks();
  const { loading: isLoadingRentability, fetch: getRentabilityResults } =
    useFetchRentability();
  const { allPortfolios } = useAllPortfolios();
  const {
    loading: isPortfolioRentabilityLoading,
    fetch: getPortfoliosRentabilityResults,
  } = useFetchPortfolioRentability();
  const { initialApplication } = useInitialApplication();
  const { isAssetsShowing, setShowAssets } = useShowAssets();
  const [generalAssetsList, setGeneralAssetsList] = useState<AssetsType>();
  const [exposedChart, setExposedChart] = useState<HighchartsTypes.Chart>();
  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();
  const [areAllPortfoliosResultsEmpty, setAreAllPortfoliosResultsEmpty] =
    useState(false);
  const { currency } = useCurrency();
  const { generateAssetColor } = useAssetColor();
  const { generateBenchmarkColor } = useBenchmarkColor();

  interface rentabilityValueType {
    value: string;
    date: string;
  }

  const [mergedRentability, setMergedRentability] = useState<{
    [x: string]: rentabilityValueType[];
  }>();

  const handleDataFetching = useCallback(async (): Promise<void> => {
    let benchmarkRentabilityResults = {};
    let portfoliosRentabilityResults: { [x: string]: string[] } = {};
    let assetsRentabilityResults = {};

    if (Object.keys(selectedBenchmarks).length > 0) {
      benchmarkRentabilityResults = await getRentabilityResults(
        initialApplication,
        selectedBenchmarks,
        selectedPeriod,
        allPortfolios
      );
    }

    if (allPortfolios[0].identifier !== "") {
      const portfoliosAssets = mergeAll(
        Object.keys(allPortfolios).map(
          (key) => allPortfolios[Number(key)].assets
        )
      );
      setGeneralAssetsList(portfoliosAssets);

      assetsRentabilityResults = await getRentabilityResults(
        initialApplication,
        portfoliosAssets,
        selectedPeriod,
        allPortfolios
      );

      portfoliosRentabilityResults = await getPortfoliosRentabilityResults(
        initialApplication,
        allPortfolios,
        selectedPeriod,
        selectedBenchmarks
      );
    }

    // Checks if ALL portfolios have no data
    const portfoliosKeys = Object.keys(portfoliosRentabilityResults);
    const emptyPortfoliosScan = portfoliosKeys
      .map(
        (portfolios) =>
          portfoliosRentabilityResults[Number(portfolios)].length === 0
      )
      .filter((checkedEmptiness) => checkedEmptiness === true);
    const isNoPortfoliosData =
      portfoliosKeys.length === emptyPortfoliosScan.length;
    setAreAllPortfoliosResultsEmpty(isNoPortfoliosData);

    setMergedRentability({
      ...portfoliosRentabilityResults,
      ...assetsRentabilityResults,
      ...benchmarkRentabilityResults,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPeriod,
    selectedBenchmarks,
    allPortfolios,
    initialApplication,
    selectedCustomDateRange,
  ]);

  useEffect(() => {
    handleDataFetching();
  }, [handleDataFetching]);

  Highcharts.setOptions({
    lang: {
      contextButtonTitle: t("chartExport.downloadImage"),
      downloadPDF: t("chartExport.downloadPDF"),
      downloadJPEG: t("chartExport.downloadJPEG"),
      downloadPNG: t("chartExport.downloadPNG"),
      decimalPoint: i18n.language === "en" ? "." : ",",
      thousandsSep: i18n.language === "en" ? "," : ".",
      resetZoom: t("rentability.resetZoom"),
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currencySymbol = t(`currency.${currency}.symbol`);

  const yLabels = {
    formatter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      return `${currencySymbol} ${Highcharts.numberFormat(this.value, 2)}`;
    },
  };

  const xLabels = {
    startOnTick: true,
    formatter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      const pointDate = this.value;

      try {
        const month = String(
          new Date(pointDate).toLocaleString(i18n.language, {
            month: "numeric",
          })
        );
        const year = new Date(pointDate).toLocaleString(i18n.language, {
          year: "2-digit",
        });
        return `${month}/${year}`;
      } catch {
        return `--`;
      }
    },
  };

  function tooltipFormatter(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/no-this-in-sfc
    return this.points.reduce(
      // eslint-disable-next-line func-names
      function (
        s: string,
        point: { series: { color: string; name: string }; y: number }
      ) {
        return `${s}<div style="display: flex; 
                justify-content: flex-start; 
                align-items: flex-start;
                padding-left: 8px;
                margin: 0;
                margin-bottom: 8px;
                color: rgba(2, 1, 1, 0.6);
                font-family: ${theme.fonts.primary};
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                max-width: 240px;
                ">
        <div style="background-color: ${point.series.color}; 
                    color: {series.color}; 
                    width: 5px; 
                    min-width: 5px;
                    max-width: 5px;
                    margin-right: 4px">
                    &nbsp;
        </div>
        <div style="font-weight: 600; min-width: 60px">
          ${`${currencySymbol} ${Highcharts.numberFormat(point.y, 2)}`}
        </div>
        <div style="white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    ">
          &nbsp;&nbsp;${point.series.name}
        </div>
    </div>`;
      },
      `    <div style="
            font-family: ${theme.fonts.primary};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin: 8px;
            color: #333333;
          ">
        ${String(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          new Date(this.x).toLocaleString(i18n.language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        )}
        </div>`
    );
  }

  const generateChartTitle = (): string => {
    if (!isEmpty(mergedRentability) && !isNil(mergedRentability)) {
      const firstRentabilityEntry = Object.keys(mergedRentability)[0];
      const firstRentabilityData = mergedRentability[firstRentabilityEntry];

      if (Object.keys(firstRentabilityData).length > 1) {
        const firstDate = new Date(
          firstRentabilityData[1].date.replaceAll("-", "/")
        ).toLocaleDateString(i18n.language);
        const lastDate = new Date(
          last(firstRentabilityData)?.date.replaceAll("-", "/") || ""
        ).toLocaleDateString(i18n.language);

        return `${t("rentability.title")} - ${firstDate} ${t(
          "rentability.to"
        )} ${lastDate}`;
      }
    }

    return "";
  };

  const handleShowAssets = (): void => {
    setShowAssets(!isAssetsShowing);
  };

  const getChart = (chart: HighchartsTypes.Chart): void =>
    setExposedChart(chart);

  const handleReflowChart = useCallback((): void => {
    try {
      if (typeof exposedChart !== "undefined") exposedChart.reflow();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [exposedChart]);

  useEffect(() => handleReflowChart, [containerWidth, handleReflowChart]);

  return (
    <SGridContainer key="RentabilityContainer" ref={containerRef}>
      {isLoadingRentability || isPortfolioRentabilityLoading ? (
        <SGridLoading key="RentabilityChartLoading">
          <SCircularProgress variant="indeterminate" color="inherit" />
        </SGridLoading>
      ) : (
        <>
          {!isNil(mergedRentability) &&
            !isEmpty(mergedRentability) &&
            !areAllPortfoliosResultsEmpty && (
              <SFloatButton
                onClick={handleShowAssets}
                $isSelected={isAssetsShowing}
              >
                <SFloatButtonIcon />
                <SFloatButtonText>
                  {isAssetsShowing
                    ? t("rentability.hideAssets")
                    : t("rentability.showAssets")}
                </SFloatButtonText>
              </SFloatButton>
            )}

          {/* No chart is shown if all portfolios results are empty */}
          {areAllPortfoliosResultsEmpty ? (
            <SGridLoading>
              <SNoResultsWarning>
                {t("rentability.noResults.line1")} <br />
                {t("rentability.noResults.line2")} <br />
                {t("rentability.noResults.line3")}
              </SNoResultsWarning>
            </SGridLoading>
          ) : (
            <HighchartsProvider Highcharts={Highcharts} key="RentabilityChart">
              <HighchartsChart
                key={`RentabilityChart-${i18n.language}`}
                callback={getChart}
                exporting={getExportingConfig(
                  80,
                  65,
                  t("rentability.tab"),
                  "",
                  theme.chartExportingButton.show,
                  {
                    enabled: true,
                    formattedAs: "money",
                    showLastValue: true,
                    currency,
                  }
                )}
                plotOptions={{
                  series: {
                    animation: false,
                  },
                }}
              >
                <Chart zoomType="x" height={550} />

                <Title
                  align="left"
                  verticalAlign="top"
                  useHTML
                  style={{
                    color: theme.colors.textDefault,
                    fontFamily: theme.fonts.primary,
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  {generateChartTitle()}
                </Title>

                {/* Subtitle is not smart enough not to render when chart is empty */}
                {mergedRentability &&
                  Object.keys(mergedRentability).length > 0 && (
                    <Subtitle
                      style={{
                        fontFamily: theme.fonts.primary,
                        fontSize: "12px",
                      }}
                    >
                      {t("rentability.zoomInfo")}
                    </Subtitle>
                  )}

                <XAxis type="datetime" labels={xLabels} />

                <YAxis labels={yLabels}>
                  {mergedRentability &&
                    Object.keys(mergedRentability).map(
                      (rentabilityAssetKey) => {
                        return (
                          <React.Fragment
                            key={`${rentabilityAssetKey}fragment`}
                          >
                            {/* Plots Benchmarks Data */}
                            {selectedBenchmarks[rentabilityAssetKey] && (
                              <LineSeries
                                turboThreshold={20000}
                                connectNulls
                                marker={{ enabled: false }}
                                color={
                                  generalAssetsList &&
                                  generateBenchmarkColor(
                                    indexOf(
                                      rentabilityAssetKey,
                                      Object.keys(selectedBenchmarks)
                                    )
                                  )
                                }
                                name={
                                  selectedBenchmarks[rentabilityAssetKey].asset
                                    .label
                                }
                                key={rentabilityAssetKey}
                                data={mergedRentability[
                                  rentabilityAssetKey
                                ].map((values) => [
                                  Date.parse(values.date),
                                  chartNumber(values.value),
                                ])}
                                tickPixelInterval={72}
                              />
                            )}
                            {/* Plots Portfolios Data */}
                            {allPortfolios[Number(rentabilityAssetKey)] && (
                              <LineSeries
                                turboThreshold={20000}
                                connectNulls
                                marker={{ enabled: false }}
                                color={generateAssetColor(
                                  Number(rentabilityAssetKey)
                                )}
                                name={
                                  allPortfolios[Number(rentabilityAssetKey)]
                                    .name
                                }
                                key={`${rentabilityAssetKey}--`}
                                data={mergedRentability[
                                  Number(rentabilityAssetKey)
                                ].map((values) => [
                                  Date.parse(values.date),
                                  chartNumber(values.value),
                                ])}
                                tickPixelInterval={72}
                              />
                            )}
                            {/* Plots Assets Data */}
                            {isAssetsShowing &&
                              generalAssetsList &&
                              generalAssetsList[rentabilityAssetKey] && (
                                <LineSeries
                                  turboThreshold={20000}
                                  connectNulls
                                  marker={{ enabled: false }}
                                  color={generateAssetColor(
                                    indexOf(
                                      rentabilityAssetKey,
                                      Object.keys(generalAssetsList)
                                    ) + Object.keys(allPortfolios).length
                                  )}
                                  name={String(
                                    generalAssetsList[rentabilityAssetKey].asset
                                      .label || "error"
                                  )}
                                  key={`${rentabilityAssetKey}--asset`}
                                  data={mergedRentability[
                                    rentabilityAssetKey
                                  ].map((values) => [
                                    Date.parse(values.date),
                                    chartNumber(values.value),
                                  ])}
                                  tickPixelInterval={72}
                                />
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                </YAxis>

                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                />

                <Tooltip
                  shared
                  useHTML
                  outside
                  borderRadius={4}
                  borderColor="rgba(51, 51, 51, 0.5)"
                  // eslint-disable-next-line react/jsx-no-bind
                  formatter={tooltipFormatter}
                  backgroundColor="rgba(255, 255, 255, 0.9)"
                  style={{ zIndex: 9999 }}
                />
              </HighchartsChart>
            </HighchartsProvider>
          )}
        </>
      )}
    </SGridContainer>
  );
};
