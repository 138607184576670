import React from "react";
import { useLocation } from "react-router-dom";
import { SGridOuterContainer, SGridContainer, SIframe } from "./styles";

export const AssetComparator: React.FC = () => {
  const location = useLocation();

  const getAssetsUrl = (currentLocation: { hash: string }): string => {
    const hasSharedUrl = currentLocation.hash !== "";
    const sharedUrl = `${
      process.env.REACT_APP_ASSETS_COMP_URL
    }/${location.hash.substring(1)}`;

    return hasSharedUrl
      ? sharedUrl
      : String(process.env.REACT_APP_ASSETS_COMP_URL);
  };

  return (
    <SGridOuterContainer>
      <SGridContainer>
        <SIframe
          title="Comparador de Ativos"
          src={getAssetsUrl(location)}
          width="100%"
          height="100%"
          allow="clipboard-write"
          sandbox="allow-scripts allow-popups allow-same-origin"
        />
      </SGridContainer>
    </SGridOuterContainer>
  );
};
