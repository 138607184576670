import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import es from "./translations/es.json";
import pt from "./translations/pt.json";

const resources = {
  ...(process.env.REACT_APP_DISABLE_PORTUGUESE === "true" ? {} : { pt }),
  ...(process.env.REACT_APP_DISABLE_ENGLISH === "true" ? {} : { en }),
  ...(process.env.REACT_APP_DISABLE_SPANISH === "true" ? {} : { es }),
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS: "common",
  fallbackLng: "pt",
  keySeparator: ".",
});
