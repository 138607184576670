import { SearchResultsType } from "../components/shared";
import { generatePortfolioName } from "../utils";

export interface BenchmarkAssetType {
  assetType: string;
  identifier: number;
  label: string;
}
export interface BenchmarkData {
  asset: BenchmarkAssetType;
}
export interface BenchmarksType {
  [key: string]: BenchmarkData;
}

export interface PortfoliosType {
  [key: number]: PortfolioType;
}
export interface PortfolioType {
  assets: AssetsType;
  assetType: "PORTFOLIO" | undefined;
  identifier: string | undefined;
  name: string;
  totalAmount: number;
}

export interface AssetData {
  amount: number | null;
  asset: SearchResultsType;
  percentage: number | null;
  isLocked: boolean;
}
export interface AssetsType {
  [key: string]: AssetData;
}

export interface CurrenciesConfigsType {
  availableCurrencies: string[];
  currentCurrency: string;
}

export interface DateRangeType {
  initialDate: string | null;
  finalDate: string | null;
}

interface MinMaxValueType {
  min: number;
  max: number;
  default: number;
}

export type CompetenceType = string[];

export type PtBrFiltersType =
  | "ACAO"
  | ""
  | "RENDA_FIXA"
  | "PREVIDENCIA"
  | "FUNDO"
  | "EUROPA"
  | "VALORIZACAO"
  | "DIVERSIFICACAO"
  | "ESTABILIDADE"
  | "ANTIFRAGILIDADE"
  | "OUTROS"
  | "BENCHMARK_PERSONALIZADO";

interface GlobalConfigsType {
  orderedPortfolioCategories: PtBrFiltersType[];
  minCharsForSearch: number;
  currency: CurrenciesConfigsType;
  language: string;
  availableConsolidated: string[];
  availableCompetences: CompetenceType;
  maxAllowedPortfolios: number;
  portfolioBeingEdited: number | null;
  showAssets: boolean;
  showSideEditor: boolean;
  customDateRange: DateRangeType;
  fetchedDateRange: DateRangeType;
  minSelectableDate: Date;
  showShareError: boolean;
  initialApplication: MinMaxValueType;
  windowValue: MinMaxValueType;
  defaultCustomBenchmarkAssetPercentage: number;
  maxCustomBenchmarkAssetPercentage: number;
  benchmarksInitialColor: number;
}

export type LoadingFromSharedLinkType = {
  [key in "isLoadingPeriods" | "isLoadingCompetence"]: boolean;
};

export interface ReduxStateType {
  applicationAmount: number;
  windowValue: number;
  benchmarks: BenchmarksType;
  searchableCustomBenchmarks: SearchResultsType[];
  showAllSearchableCustomBenchmarks: boolean;
  currentPortfolioId: number | null;
  sharedLinkTasks: LoadingFromSharedLinkType;
  globalConfigs: GlobalConfigsType;
  period:
    | "OTIMO"
    | "NO_MES"
    | "UM_ANO_UTIL"
    | "SEIS_MESES_UTEIS"
    | "CINCO_ANOS_UTEIS"
    | "DATA_PERSONALIZADA"
    | string;
  consolidated:
    | "ATIVOS"
    | "TIPO_DO_ATIVO"
    | "SETOR_DO_ATIVO"
    | "RISCO_DO_ATIVO"
    | "CLASSE_DO_ATIVO"
    | string;
  competence: string;
  portfolios: PortfoliosType;
  spinner: boolean;
}

export const initialState: ReduxStateType = {
  applicationAmount: 100,
  windowValue: 20,
  benchmarks: {},
  searchableCustomBenchmarks: [],
  showAllSearchableCustomBenchmarks: false,
  currentPortfolioId: null,
  sharedLinkTasks: {
    isLoadingPeriods: false,
    isLoadingCompetence: false,
  },
  globalConfigs: {
    orderedPortfolioCategories: [
      "ESTABILIDADE",
      "DIVERSIFICACAO",
      "VALORIZACAO",
      "ANTIFRAGILIDADE",
      "OUTROS",
      "ACAO",
      "RENDA_FIXA",
      "PREVIDENCIA",
      "FUNDO",
      "BENCHMARK_PERSONALIZADO",
      "",
    ],
    minCharsForSearch: 3,
    currency: {
      currentCurrency: "BRL",
      availableCurrencies: ["BRL", "USD", "EUR"],
    },
    availableConsolidated: [
      "ATIVOS",
      "CLASSE_DO_ATIVO",
      "RISCO_DO_ATIVO",
      "SETOR_DO_ATIVO",
      "TIPO_DO_ATIVO",
    ],
    availableCompetences: [],
    language: "",
    maxAllowedPortfolios: 2,
    portfolioBeingEdited: null,
    showSideEditor: false,
    showAssets: false,
    customDateRange: { initialDate: null, finalDate: null },
    fetchedDateRange: { initialDate: null, finalDate: null },
    minSelectableDate: new Date("01-01-1970"),
    showShareError: false,
    initialApplication: {
      min: 1,
      max: 1_000_000_000,
      default: 100,
    },
    windowValue: {
      min: 1,
      max: 1_000,
      default: 20,
    },
    defaultCustomBenchmarkAssetPercentage: 100,
    maxCustomBenchmarkAssetPercentage: 999.999,
    benchmarksInitialColor: 12,
  },
  period: "UM_ANO_UTIL",
  consolidated: "ATIVOS",
  competence: "",
  portfolios: {
    0: {
      assets: {},
      assetType: "PORTFOLIO",
      identifier: "",
      name: generatePortfolioName({}),
      totalAmount: 0,
    },
  },
  spinner: false,
};

export * from "./hooks";
export * from "./reducer";
