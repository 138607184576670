/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppBar, Grid, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SAppBar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.colors.navbarBg};
    color: ${({ theme }) => theme.colors.navbarFg};
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

    .MuiToolbar-root {
      min-height: 56px;
    }
  }
`;

export const SMenuItems = styled(Grid)`
  && {
    @media (max-width: 920px) {
      flex-wrap: auto;
      align-items: flex-start;
    }
  }
`;

interface SMenuItemPropsType {
  $selected?: boolean;
  // All other props
  [x: string]: any;
}

export const SMenuItem = styled(Typography)<SMenuItemPropsType>`
  && {
    color: ${({ theme }) => theme.colors.navbarFg};
    margin: 0;
    padding: 0;
    margin-left: 90px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 700;
    cursor: pointer;
    height: 40px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: ${({ theme, $selected }) =>
      $selected
        ? `3px solid ${theme.colors.navbarSelectionLine}`
        : "3px solid rgba(0,0,0,0)"};

    && {
      @media (max-width: 920px) {
        margin-left: 30px;
        white-space: nowrap;
      }
    }
  }
`;

export const SLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.navbarFg};
  }
`;

export const SToolbar = styled(Toolbar)`
  && {
    margin-right: 0;
    padding-right: 0 !important;
    display: flex;
    align-items: flex-end;

    @media (max-width: 920px) {
      display: flex;
      align-items: center;
    }
  }
`;

export const SLogoImg = styled.img`
  && {
    margin-top: 3px;
    margin-bottom: 3px;

    @media (max-width: 920px) {
      height: unset;
      max-height: 80px;
      width: 30%;
      max-width: 150px;
    }
  }
`;
