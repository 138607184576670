import { useDispatch, useSelector } from "react-redux";
import {
  BenchmarkAssetType,
  CompetenceType,
  DateRangeType,
  ReduxStateType,
} from "./index";
import {
  addBenchmark,
  addNewPortfolio,
  RuleType,
  addOrUpdateAssetsToPortfolio,
  ReduxActions,
  removeAssetFromPortfolio,
  removeBenchmark,
  renameCurrentPortfolio,
  setCurrentPortfolio,
  setLanguage,
  setPeriod,
  setSpinner,
  balancePortfolio,
  addOrUpdateRemotePortfolioId,
  updateTotalAmount,
  setApplicationAmount,
  clonePortfolio,
  showSideEditor,
  updatePortfolioBeingEdited,
  showAssets,
  removePortfolio,
  setCurrency,
  updateFetchedDateRange,
  updateCustomDateRange,
  updateAssetLockInPortfolio,
  updateWindow,
  setConsolidated,
  setCompetence,
  showShareError,
  unlockAllPortfolioAssets,
  updateLoadingFromSharedLink,
  setStoreSharedLink,
  addCustomBenchmarkAsset,
  updateAvailableCompetences,
  updateShowAllSearchableCustomBenchmarks,
} from "./actions";
import { SearchResultsType } from "../components/shared";
import { useAddOrUpdateRemotePortfolio } from "../api";
import { AppDispatchType, CallableStateType } from "..";

export const useStoreForSharedLink = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const storeCopy = useSelector((state: ReduxStateType) => state);

  const setStoreForSharedLink = (state: ReduxStateType): ReduxActions =>
    dispatch(setStoreSharedLink(state));

  const hookReturn = {
    storeCopy,
    setStoreForSharedLink,
  };

  return hookReturn;
};

export const useSpinner = (): [typeof spinner, typeof setSpinnerState] => {
  const dispatch = useDispatch();
  const spinner = useSelector((state: ReduxStateType) => state.spinner);

  const setSpinnerState = (spinnerState: boolean): ReduxActions =>
    dispatch(setSpinner(spinnerState));

  return [spinner, setSpinnerState];
};

export const useLanguage = (): [typeof language, typeof setLanguageState] => {
  const dispatch = useDispatch();
  const language = useSelector(
    (state: ReduxStateType) => state.globalConfigs.language
  );

  const setLanguageState = (lang: string): ReduxActions =>
    dispatch(setLanguage(lang));

  return [language, setLanguageState];
};

export const useCurrency = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const currency = useSelector(
    (state: ReduxStateType) => state.globalConfigs.currency.currentCurrency
  );

  const availableCurrencies = useSelector(
    (state: ReduxStateType) => state.globalConfigs.currency.availableCurrencies
  );

  const setCurrencyState = (lang: string): ReduxActions =>
    dispatch(setCurrency(lang));

  const hookReturn = {
    currency,
    setCurrency: setCurrencyState,
    availableCurrencies,
  };

  return hookReturn;
};

export const useCurrentPortfolioId = (): [
  typeof currentPortfolioId,
  typeof setCurrentPortfolioId
] => {
  const dispatch = useDispatch();
  const currentPortfolioId = useSelector(
    (state: ReduxStateType) => state.currentPortfolioId
  );

  const setCurrentPortfolioId = (
    portfolioNumber: number | null
  ): ReduxActions => dispatch(setCurrentPortfolio(portfolioNumber));

  return [currentPortfolioId, setCurrentPortfolioId];
};

export const useCurrentPortfolio = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const { send: addOrUpdateRemotePortfolio } = useAddOrUpdateRemotePortfolio();

  const currentPortfolioId = useSelector(
    (state: ReduxStateType) => state.currentPortfolioId
  );

  const selectedPortfolio = useSelector((state: ReduxStateType) =>
    currentPortfolioId === null
      ? null
      : {
          ...state.portfolios[currentPortfolioId],
        }
  );

  const updateRemotePortfolio = (): void => {
    dispatch(async (_: AppDispatchType, state: CallableStateType) => {
      const currentPortfolioContent =
        state().portfolios[currentPortfolioId || 0];
      const remotePortfolioId = await addOrUpdateRemotePortfolio(
        currentPortfolioContent
      );

      dispatch(
        addOrUpdateRemotePortfolioId(currentPortfolioId || 0, remotePortfolioId)
      );
    });
  };

  const addPortfolioAsset = (
    rule: RuleType,
    portfolioAssets: SearchResultsType,
    percentage: number | null,
    amount: number | null
  ): void => {
    dispatch(
      addOrUpdateAssetsToPortfolio(
        portfolioAssets,
        currentPortfolioId || 0,
        percentage,
        amount,
        rule,
        false
      )
    );
    updateRemotePortfolio();
  };

  const updatePortfolioAsset = (
    rule: RuleType,
    portfolioAssets: SearchResultsType,
    percentage: number | null,
    amount: number | null,
    isLocked: boolean
  ): void => {
    dispatch(
      addOrUpdateAssetsToPortfolio(
        portfolioAssets,
        currentPortfolioId || 0,
        percentage,
        amount,
        rule,
        isLocked
      )
    );
    updateRemotePortfolio();
  };

  const removePortfolioAsset = (assetId: string): void => {
    dispatch(removeAssetFromPortfolio(assetId, currentPortfolioId || 0));
    updateRemotePortfolio();
  };

  const updatePortfolioAssetLock = (assetId: string, locked: boolean): void => {
    dispatch(
      updateAssetLockInPortfolio(assetId, currentPortfolioId || 0, locked)
    );
    updateRemotePortfolio();
  };

  const portfolioAssets = useSelector(
    (state: ReduxStateType) => state.portfolios[currentPortfolioId || 0].assets
  );

  const portfolioName = useSelector(
    (state: ReduxStateType) => state.portfolios[currentPortfolioId || 0].name
  );

  const setPortfolioName = (newName: string): void => {
    dispatch(renameCurrentPortfolio(newName, currentPortfolioId || 0));
    updateRemotePortfolio();
  };

  const updatePortfolioTotal = (totalAmount: number): void => {
    dispatch(updateTotalAmount(totalAmount, currentPortfolioId || 0));
    updateRemotePortfolio();
  };

  const balanceCurrentPortfolio = (): void => {
    dispatch(balancePortfolio(currentPortfolioId || 0));
    updateRemotePortfolio();
  };

  const isLastPortfolio: boolean =
    Object.keys(useSelector((state: ReduxStateType) => state.portfolios))
      .length === 1;

  const isLastAssetInPortfolio: boolean = selectedPortfolio?.assets
    ? Object.keys(selectedPortfolio?.assets).length === 1
    : true;

  const isAnyAssetLocked: boolean = selectedPortfolio?.assets
    ? Object.keys(selectedPortfolio.assets).some(
        (asset) => selectedPortfolio.assets?.[asset].isLocked
      )
    : false;

  const removeCurrentPortfolio = (): void => {
    if (currentPortfolioId !== null)
      dispatch(removePortfolio(currentPortfolioId));
  };

  const unlockPortfolioAssets = (): void => {
    if (currentPortfolioId !== null) {
      dispatch(unlockAllPortfolioAssets(currentPortfolioId));
      updateRemotePortfolio();
    }
  };

  const hookReturn = {
    selectedPortfolio,
    portfolioAssets,
    addPortfolioAsset,
    updatePortfolioAsset,
    removePortfolioAsset,
    updatePortfolioAssetLock,
    portfolioName,
    setPortfolioName,
    updatePortfolioTotal,
    balanceCurrentPortfolio,
    isLastPortfolio,
    isLastAssetInPortfolio,
    removeCurrentPortfolio,
    updateRemotePortfolio,
    unlockPortfolioAssets,
    isAnyAssetLocked,
  };
  return hookReturn;
};

export const useAllPortfolios = (): typeof returnHook => {
  const dispatch = useDispatch();

  const allPortfolios = useSelector(
    (state: ReduxStateType) => state.portfolios
  );

  const allPortfoliosWithNamedKeys = useSelector((state: ReduxStateType) => {
    const arrayOfPortfolios = Object.keys(state.portfolios).map(
      (portfolioKey) => ({
        [`${String(
          state.portfolios[Number(portfolioKey)].assetType
        )}❤❤❤${String(state.portfolios[Number(portfolioKey)].identifier)}`]: {
          ...state.portfolios[Number(portfolioKey)],
        },
      })
    );

    return arrayOfPortfolios;
  });

  const createNewPortfolio = (): void => {
    dispatch(addNewPortfolio());
  };

  const returnHook = {
    allPortfolios,
    allPortfoliosWithNamedKeys,
    createNewPortfolio,
  };

  return returnHook;
};

export const usePeriod = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const selectedPeriod = useSelector((state: ReduxStateType) => state.period);

  const setSelectedPeriod = (period: ReduxStateType["period"]): void => {
    dispatch(setPeriod(period));
  };

  const fetchedPeriodDate = useSelector(
    (state: ReduxStateType) => state.globalConfigs.fetchedDateRange
  );

  const setFetchedPeriodDate = (dateRange: DateRangeType): void => {
    dispatch(updateFetchedDateRange(dateRange));
  };

  const hookReturn = {
    selectedPeriod,
    setSelectedPeriod,
    fetchedPeriodDate,
    setFetchedPeriodDate,
  };

  return hookReturn;
};

export const useBenchmarks = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const selectedBenchmarks = useSelector(
    (state: ReduxStateType) => state.benchmarks
  );

  const addSelectedBenchmark = (benchmarkItem: BenchmarkAssetType): void => {
    dispatch(addBenchmark(benchmarkItem));
  };

  const removeSelectedBenchmark = (benchmarkId: string): void => {
    dispatch(removeBenchmark(benchmarkId));
  };

  const hookReturn = {
    selectedBenchmarks,
    addSelectedBenchmark,
    removeSelectedBenchmark,
  };

  return hookReturn;
};

export const useMaxAllowedPortfolios = (): typeof hookReturn => {
  const allPortfolios = useSelector(
    (state: ReduxStateType) => state.portfolios
  );
  const maxAllowedPortfolios = useSelector(
    (state: ReduxStateType) => state.globalConfigs.maxAllowedPortfolios
  );

  const isPortfoliosMaxedOut = (): boolean =>
    Object.keys(allPortfolios).length >= maxAllowedPortfolios;

  const hookReturn = {
    maxAllowedPortfolios,
    isPortfoliosMaxedOut,
  };

  return hookReturn;
};

export const useInitialApplication = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const initialApplication = useSelector(
    (state: ReduxStateType) => state.applicationAmount
  );

  const setInitialApplication = (amount: number): void => {
    dispatch(setApplicationAmount(amount));
  };

  const hookReturn = {
    initialApplication,
    setInitialApplication,
  };

  return hookReturn;
};

export const useClonePortfolio = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const { send: addOrUpdateRemotePortfolio } = useAddOrUpdateRemotePortfolio();

  const allPortfolios = useSelector(
    (state: ReduxStateType) => state.portfolios
  );

  const clonePortfolioFromId = async (
    portfolioNumber: number
  ): Promise<void> => {
    const newPortfolioContent = {
      ...allPortfolios[portfolioNumber],
      identifier: "",
    };
    const remotePortfolioId = await addOrUpdateRemotePortfolio(
      newPortfolioContent
    );

    dispatch(clonePortfolio(portfolioNumber, remotePortfolioId));
  };

  const hookReturn = {
    clonePortfolioFromId,
  };

  return hookReturn;
};

export const useSideEditor = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const isSideEditorActive = useSelector(
    (state: ReduxStateType) => state.globalConfigs.showSideEditor
  );

  const setSideEditorActive = (show: boolean): void => {
    dispatch(showSideEditor(show));
  };

  const setPortfolioBeingEdited = (id: number | null): void => {
    dispatch(updatePortfolioBeingEdited(id));
  };

  const portfolioBeingEdited = useSelector(
    (state: ReduxStateType) => state.globalConfigs.portfolioBeingEdited
  );

  const hookReturn = {
    isSideEditorActive,
    setSideEditorActive,
    portfolioBeingEdited,
    setPortfolioBeingEdited,
  };

  return hookReturn;
};

export const useShowAssets = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const isAssetsShowing = useSelector(
    (state: ReduxStateType) => state.globalConfigs.showAssets
  );

  const setShowAssets = (show: boolean): void => {
    dispatch(showAssets(show));
  };

  const hookReturn = {
    isAssetsShowing,
    setShowAssets,
  };

  return hookReturn;
};

export const useCustomDateRange = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const selectedCustomDateRange = useSelector(
    (state: ReduxStateType) => state.globalConfigs.customDateRange
  );

  const setCustomDateRange = (dateRange: DateRangeType): void => {
    dispatch(updateCustomDateRange(dateRange));
  };

  const hookReturn = {
    selectedCustomDateRange,
    setCustomDateRange,
  };

  return hookReturn;
};

export const useGlobalConfigs = (): typeof hookReturn => {
  const minDate = useSelector(
    (state: ReduxStateType) => state.globalConfigs.minSelectableDate
  );

  const windowValue = useSelector(
    (state: ReduxStateType) => state.globalConfigs.windowValue
  );

  const initialApplication = useSelector(
    (state: ReduxStateType) => state.globalConfigs.initialApplication
  );

  const defaultCustomBenchmarkAssetPercentage = useSelector(
    (state: ReduxStateType) =>
      state.globalConfigs.defaultCustomBenchmarkAssetPercentage
  );

  const minCharsForSearch = useSelector(
    (state: ReduxStateType) => state.globalConfigs.minCharsForSearch
  );

  const maxCustomBenchmarkAssetPercentage = useSelector(
    (state: ReduxStateType) =>
      state.globalConfigs.maxCustomBenchmarkAssetPercentage
  );

  const benchmarksInitialColor = useSelector(
    (state: ReduxStateType) => state.globalConfigs.benchmarksInitialColor
  );

  const orderedPortfolioCategories = useSelector(
    (state: ReduxStateType) => state.globalConfigs.orderedPortfolioCategories
  );

  const hookReturn = {
    windowValue,
    initialApplication,
    minDate,
    defaultCustomBenchmarkAssetPercentage,
    minCharsForSearch,
    maxCustomBenchmarkAssetPercentage,
    benchmarksInitialColor,
    orderedPortfolioCategories,
  };

  return hookReturn;
};

export const useSharedLink = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const sharedLinkTasks = useSelector(
    (state: ReduxStateType) => state.sharedLinkTasks
  );

  const setLoadingPeriodsFromSharedLink = (loadingState: boolean): void => {
    dispatch(updateLoadingFromSharedLink(loadingState, "isLoadingPeriods"));
  };

  const setLoadingCompetenceFromSharedLink = (loadingState: boolean): void => {
    dispatch(updateLoadingFromSharedLink(loadingState, "isLoadingCompetence"));
  };

  const hookReturn = {
    sharedLinkTasks,
    setLoadingPeriodsFromSharedLink,
    setLoadingCompetenceFromSharedLink,
  };

  return hookReturn;
};

export const useWindowValue = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const windowValue = useSelector((state: ReduxStateType) => state.windowValue);

  const setWindowValue = (windowNumber: number): void => {
    dispatch(updateWindow(windowNumber));
  };

  const hookReturn = {
    windowValue,
    setWindowValue,
  };

  return hookReturn;
};

export const useWallet = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const selectedConsolidated = useSelector(
    (state: ReduxStateType) => state.consolidated
  );

  const availableConsolidated = useSelector(
    (state: ReduxStateType) => state.globalConfigs.availableConsolidated
  );

  const setSelectedConsolidated = (
    consolidatedBy: ReduxStateType["consolidated"]
  ): void => {
    dispatch(setConsolidated(consolidatedBy));
  };

  const selectedCompetence = useSelector(
    (state: ReduxStateType) => state.competence
  );

  const setSelectedCompetence = (
    competence: ReduxStateType["competence"]
  ): void => {
    dispatch(setCompetence(competence));
  };

  const availableCompetences = useSelector(
    (state: ReduxStateType) => state.globalConfigs.availableCompetences
  );

  const setAvailableCompetences = (competences: CompetenceType): void => {
    dispatch(updateAvailableCompetences(competences));
  };

  const hookReturn = {
    availableConsolidated,
    selectedConsolidated,
    setSelectedConsolidated,
    selectedCompetence,
    setSelectedCompetence,
    availableCompetences,
    setAvailableCompetences,
  };

  return hookReturn;
};

export const useShowShareError = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const shareError = useSelector(
    (state: ReduxStateType) => state.globalConfigs.showShareError
  );

  const setShareError = (error: boolean): void => {
    dispatch(showShareError(error));
  };

  const hookReturn = {
    shareError,
    setShareError,
  };

  return hookReturn;
};

export const useCustomBenchmarkAsset = (): typeof hookReturn => {
  const dispatch = useDispatch();
  const customBenchmarks = useSelector(
    (state: ReduxStateType) => state.searchableCustomBenchmarks
  );

  const addToCustomBenchmarkAssets = (
    customAssetItem: SearchResultsType
  ): void => {
    dispatch(addCustomBenchmarkAsset(customAssetItem));
  };

  const showAllSearchableCustomBenchmarks = useSelector(
    (state: ReduxStateType) => state.showAllSearchableCustomBenchmarks
  );

  const setShowAllSearchableCustomBenchmarks = (show: boolean): void => {
    dispatch(updateShowAllSearchableCustomBenchmarks(show));
  };

  const hookReturn = {
    customBenchmarks,
    addToCustomBenchmarkAssets,
    showAllSearchableCustomBenchmarks,
    setShowAllSearchableCustomBenchmarks,
  };

  return hookReturn;
};
