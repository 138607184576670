import logo from "../assets/logo-xpi.png";

import { ThemeType } from "./defaultTheme";

const themeProps = {
  fonts: {
    primary: "Open Sans, sans-serif",
    pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
    quote: "Georgia, serif",
  },
  logo: {
    img: logo,
    height: "78px",
    padding: "10px 0px",
    align: "center",
    show: true,
  },
  navbar: {
    show: true,
    startingRoute: "/asset",
  },
  localeSettings: {
    showSettingsGearButton: true,
    showInsideLeftColumn: true,
  },
  assetSearchShowAllFromSelectedCategory: false,
  assetSearch: {
    funds: {
      show: true,
    },
    stocks: {
      show: true,
    },
    fixedIncome: {
      show: true,
    },
    valorization: {
      show: false,
    },
    diversification: {
      show: false,
    },
    stability: {
      show: false,
    },
    antifragility: {
      show: false,
    },
    others: {
      show: false,
    },
    createAsset: {
      show: true,
    },
  },
  benchmarks: {
    others: {
      show: true,
    },
  },
  portfolios: {
    singlePortfolioMode: false,
    addPortoflio: {
      show: true,
    },
    namePrefix: {
      useCustom: false,
      name: "",
    },
  },
  portfolioSummary: {
    compactList: false,
    noCardIcons: false,
  },
  chartExportingButton: {
    show: true,
  },
  defaultPeriod: {
    useCustom: false,
    key: "",
  },
  colors: {
    navbarBg: "white",
    navbarFg: "#343A40",
    navbarSelectionLine: "#212529",
    textDefault: "#212529",
    textFaded: "rgba(37, 64, 87, 0.5)",
    textDark: "#212529",
    textGray: "rgba(51, 51, 51, 0.6)",
    textWhite: "#FFFFFF",
    textBlack: "#000000",
    textDarkGray: "#333333",
    bgPrimary: "white",
    bgSecondary: "gray",
    bgGray: "#F1F3F5",
    iconCyan: "#00A9FE",
    selectedBlue: "#09B4FF",
    selectedLightBlue: "#D9F2FF",
    funds: "#202020",
    stocks: "#FBC105",
    fixedIncome: "#90BE6D",
    pension: "#32A32A",
    valorization: "#DF0B0B",
    diversification: "#604AF0",
    stability: "#F09C62",
    antifragility: "#BE56F0",
    others: "#32A32A",
    createAsset: "#5C7E05",
    leftover: "rgba(51, 51, 51, 0.6)",
    custom: "#3E85F0",
    addButtonPrimary: "#212529",
    addButtonSecondary: "rgba(37,64,87,0.48)",
    addButtonText: "#FFFFFF",
    addButtonHoverFg: "#FFFFFF",
    removeButtonPrimary: "#212529",
    removeButtonSecondary: "rgba(223, 11, 11, 0.48)",
    removeButtonText: "#FFFFFF",
    removeButtonHoverFg: "#FFFFFF",
    cardBorder: "#F2F1F6",
    cardBg: "#FFFFFF",
    cardBgSelected: "#D9F2FF",
    cardCheckboxChecked: "#00A9FE",
    cardCheckboxUnchecked: "rgba(51, 51, 51, 0.25)",
    periodButtonDark: "#333333",
    periodButtonLight: "#FFFFFF",
    periodButtonSelected: "#FFC709",
    periodButtonSelectedFg: "#212529",
    periodButtonHoverFg: "#212529",
    periodButtonHover: "#FFDD6B",
    portfolioButtonHover: "#EFF1F3",
    accordionTitleBg: "#373A3E",
    accordionTitleFg: "white",
    alertRed: "#DF0B0B",
    showAssetsButtonLight: "#FFFFFF",
    showAssetsButtonHover: "#FFDD6B",
    showAssetsButtonHoverFg: "#212529",
    showAssetsButtonDark: "#333333",
    showAssetsButtonSelected: "#FFC709",
    showAssetsButtonSelectedFg: "#212529",
    bgQuantumBanner: "#212529",
    addAssetBorder: "#212529",
    addAssetTextColor: "#212529",
    addAssetBackground: "#D9F2FF",
    snackbarMessageSuccess: "#373a3e",
    snackbarMessageError: "#DF0B0B",
    shareButtonLight: "#FFFFFF",
    shareButtonHover: "#4D5154",
    shareButtonDark: "#27292A",
    shareButtonHoverFg: "#FFFFFF",
    assets: [
      "#d0cecd",
      "#f9c74f",
      "#3b3b3b",
      "#909090",
      "#277da1",
      "#f94144",
      "#90be6d",
      "#f9844a",
      "#43aa8b",
      "#d0cecd",
      "#4d908e",
      "#f8961e",
      "#4ab7ff",
      "#d10749",
      "#e3a463",
      "#737373",
      "#b6b4b3",
      "#3b3b3b",
      "#e8dcc2",
      "#b8a477",
    ],
    deletePortfolioRed: "#DF0B0B",
  },
};

export const blankLabelTheme: ThemeType = themeProps;
