import styled from "styled-components";
import { IconButton, Grid, Typography, Popover, Button } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeIcon from "@mui/icons-material/QrCode";

export const SGridContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`;

export const SButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;

    margin: 0;
    margin-right: 5px;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;

    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.shareButtonDark};
    background: ${({ theme }) => theme.colors.shareButtonLight};

    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    &:hover {
      background-color: ${({ theme }) => theme.colors.shareButtonHover};
      color: ${({ theme }) => theme.colors.shareButtonHoverFg};
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SPopover = styled(Popover)`
  && {
    margin: 0;
    left: -28px;
  }
`;

export const SContentCopyIcon = styled(ContentCopyIcon)`
  && {
  }
`;

export const SQrCodeIcon = styled(QrCodeIcon)`
  && {
  }
`;

export const SShareMessage = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textBlack};
    margin-left: 18px;
  }
`;

interface ErrorOnCopy {
  $error: boolean;
}

export const SPopoverMessageContainer = styled(Grid)<ErrorOnCopy>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ $error, theme }) =>
      $error
        ? theme.colors.snackbarMessageError
        : theme.colors.snackbarMessageSuccess};
    min-height: 58px;
    border-radius: 5px;

    border-radius: 5px;
    padding: 8px;
    padding-right: 40px;
    padding-left: 30px;
  }
`;

export const SPopoverMessage = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    grid-gap: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;

export const SClosePopoverButton = styled(IconButton)`
  && {
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;

export const SClosePopoverIcon = styled(CancelRoundedIcon)`
  && {
    color: ${({ theme }) => theme.colors.textWhite};
    font-size: 20px;
  }
`;
