/* eslint-disable @typescript-eslint/no-explicit-any */
import Big from "big.js";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, {
  NumberFormatProps,
  SourceInfo,
} from "react-number-format";
import { useCurrency, useLanguage } from "../../../store";

interface CurrencyInputPropsType {
  $hideCurrencySymbol?: boolean;
  $focusOnMount?: boolean;
  // All other props
  [x: string]: any;
}

export const CurrencyInput: React.FC<CurrencyInputPropsType> = ({
  onValueChange,
  ...props
}: NumberFormatProps) => {
  CurrencyInput.defaultProps = {
    $hideCurrencySymbol: false,
    $focusOnMount: false,
  };

  const [language] = useLanguage();
  const { currency } = useCurrency();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string | number>(
    Big(props.value || 0)
      .times(10000)
      .toNumber() || ""
  );

  const handleChange = (v: any, sourceInfo: SourceInfo): void => {
    // Sincronizes inside and outside states
    setValue(
      Big(v.value || 0)
        .times(100)
        .toNumber()
    );

    if (onValueChange) {
      onValueChange(
        {
          ...v,
          floatValue: Big(v.floatValue || 0)
            .div(100)
            .toNumber(),
        },
        sourceInfo
      );
    }
  };

  const currencyFormatter = (formattedValue: any): string => {
    const br = { style: "currency", currency: "BRL" };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (props.$hideCurrencySymbol) {
      return new Intl.NumberFormat(language || "pt-BR", br)
        .formatToParts(
          Big(formattedValue || 0)
            .div(100)
            .toNumber()
        )
        .map((parameter) =>
          parameter.type !== "literal" && parameter.type !== "currency"
            ? parameter.value
            : ""
        )
        .join("");
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currencySymbol = t(`currency.${currency}.symbol`);
    return `${currencySymbol} ${new Intl.NumberFormat(language || "pt-BR", br)
      .formatToParts(
        Big(formattedValue || 0)
          .div(100)
          .toNumber()
      )
      .map((parameter) =>
        parameter.type !== "literal" && parameter.type !== "currency"
          ? parameter.value
          : ""
      )
      .join("")}`;
  };

  const keyDown: React.KeyboardEventHandler = (event): void => {
    if (props?.onKeyDown) {
      props.onKeyDown(event as React.KeyboardEvent<HTMLInputElement>);
    }

    // Keeps the cursor position on erase if the value is === 0
    if (event.code === "Backspace" && !value) {
      event.preventDefault();
    }
    // Sets the value to 0 and prevents the default so curor remains in "cents" area
    if (event.code === "Backspace" && value < 1000) {
      event.preventDefault();
      setValue(0);
    }
  };

  // Sincronizes inside and outside states
  useEffect(
    () =>
      setValue(
        Big(props.value || 0)
          .times(10000)
          .toNumber()
      ),
    [props.value]
  );

  // Debug tool:
  //
  //  const focusLog = (): void => {
  //    console.log(">>> Prop recebida:", props.value);
  // };

  const onFocus = (): void => {
    if (inputRef?.current?.firstChild) {
      const input = inputRef?.current?.firstChild as HTMLInputElement;
      const valueSize = String(input.value).length;
      input.select();
      input.selectionEnd = valueSize - 3;
      input.selectionStart = 3;
    }
  };

  // const handleClick = (e: any): void => {
  //   try {
  //     if (value === 0) {
  //       const valueSize = String(e.target.value).length;
  //       e.target.selectionEnd = valueSize;
  //       e.target.selectionStart = valueSize;
  //     }
  //   } catch {
  //     // ignore error
  //   }
  // };

  //  const inputRef = React.createRef<HTMLElement>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (props.$focusOnMount && inputRef?.current?.firstChild) {
      const input = inputRef.current.firstChild as HTMLInputElement;
      input.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={Big(Number(value)).div(100).toNumber()}
      format={currencyFormatter}
      onValueChange={handleChange}
      allowEmptyFormatting
      onKeyDown={keyDown}
      onFocus={onFocus}
      // onClick={handleClick}
      getInputRef={inputRef}
    />
  );
};

export default CurrencyInput;
