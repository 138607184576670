import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { useTheme } from "styled-components";
import { NavBar } from "./components";
import {
  PortfolioAnalysis,
  PortfolioComposer,
  PortfolioEditorPage,
  PortfolioShared,
  AssetComparator,
} from "./pages";

const compareAssetsSharedLinkPattern = /asset#assets/;

const App: React.FC = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { startingRoute } = theme.navbar;

  useEffect(() => {
    if (!compareAssetsSharedLinkPattern.test(window.location.href)) {
      navigate(`.${startingRoute}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {theme.navbar.show && <NavBar />}
      <Routes>
        <Route path="/" element={<PortfolioComposer />} />
        <Route path="editor" element={<PortfolioEditorPage />} />
        <Route path="analysis" element={<PortfolioAnalysis />} />
        <Route path="shared" element={<PortfolioShared />} />
        <Route path="asset" element={<AssetComparator />}>
          <Route path=":assetSharedLink" element={<AssetComparator />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
