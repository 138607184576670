import { miliSecondsDelay } from "./DelayUtils";

/**
 * Pools delayed requests until a stop condition is met.
 * @param fn - function you want it to be repeated
 * @param fnStopCondition - a function that returns the condition to stop the pooling
 * @returns the result of the `fn` function
 * @example
 * ```ts
 * const data = await poolRequest( () => api.get(...), (response) => response.data === "OK", 3000)
 * ```
 */
export const poolRequest = async <T>(
  fn: () => Promise<T>,
  fnStopCondition: (result: T) => boolean,
  delayBetweenRetries = 2000
): Promise<T> => {
  let result = await fn();
  while (fnStopCondition(result)) {
    // eslint-disable-next-line no-await-in-loop
    await miliSecondsDelay(delayBetweenRetries);
    // eslint-disable-next-line no-await-in-loop
    result = await fn();
  }
  return result;
};
