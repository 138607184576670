import type * as HighchartsTypes from "highcharts";
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  Legend,
  LineSeries,
  Subtitle,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { indexOf, isEmpty, isNil, last, mergeAll } from "ramda";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import React, { useCallback, useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { WindowSelector } from "./WindowSelector/WindowSelector";
import {
  SCircularProgress,
  SFloatButton,
  SGridContainer,
  SFloatButtonIcon,
  SFloatButtonText,
  SGridLoading,
  SNoResultsWarning,
  SFloatingContainer,
} from "./styles";
import {
  getExportingConfig,
  chartNumber,
  useAssetColor,
  useBenchmarkColor,
} from "../../../utils";
import {
  useAllPortfolios,
  useBenchmarks,
  useInitialApplication,
  usePeriod,
  AssetsType,
  useShowAssets,
  useCustomDateRange,
  useWindowValue,
} from "../../../store";
import { useFetchPortfolioRisk, useFetchRisk } from "../../../api";

HighchartsExporting(Highcharts);

export const RiskChart: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { selectedPeriod } = usePeriod();
  const { selectedCustomDateRange } = useCustomDateRange();
  const { selectedBenchmarks } = useBenchmarks();
  const { loading: isLoadingRentability, fetch: getRentabilityResults } =
    useFetchRisk();
  const { allPortfolios } = useAllPortfolios();
  const {
    loading: isPortfolioRentabilityLoading,
    fetch: getPortfoliosRentabilityResults,
  } = useFetchPortfolioRisk();
  const { initialApplication } = useInitialApplication();
  const { isAssetsShowing, setShowAssets } = useShowAssets();
  const [generalAssetsList, setGeneralAssetsList] = useState<AssetsType>();
  const [exposedChart, setExposedChart] = useState<HighchartsTypes.Chart>();
  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();
  const [areAllPortfoliosResultsEmpty, setAreAllPortfoliosResultsEmpty] =
    useState(false);
  const { windowValue } = useWindowValue();
  // const { windowValue: defaultWindowValue } = useGlobalConfigs();
  const { generateAssetColor } = useAssetColor();
  const { generateBenchmarkColor } = useBenchmarkColor();
  interface riskValueType {
    value: string;
    date: string;
  }

  const [mergedRisk, setMergedRisk] = useState<{
    [x: string]: riskValueType[];
  }>();

  const handleDataFetching = useCallback(async (): Promise<void> => {
    let benchmarkRiskResults = {};
    let portfoliosRiskResults: { [x: string]: string[] } = {};
    let assetsRiskResults = {};

    if (Object.keys(selectedBenchmarks).length > 0) {
      benchmarkRiskResults = await getRentabilityResults(
        initialApplication,
        selectedBenchmarks,
        selectedPeriod,
        allPortfolios,
        windowValue
      );
    }

    if (allPortfolios[0].identifier !== "") {
      const portfoliosAssets = mergeAll(
        Object.keys(allPortfolios).map(
          (key) => allPortfolios[Number(key)].assets
        )
      );
      setGeneralAssetsList(portfoliosAssets);

      assetsRiskResults = await getRentabilityResults(
        initialApplication,
        portfoliosAssets,
        selectedPeriod,
        allPortfolios,
        windowValue
      );

      portfoliosRiskResults = await getPortfoliosRentabilityResults(
        initialApplication,
        allPortfolios,
        selectedPeriod,
        selectedBenchmarks,
        windowValue
      );
    }

    // Checks if ALL portfolios have no data
    const portfoliosKeys = Object.keys(portfoliosRiskResults);
    const emptyPortfoliosScan = portfoliosKeys
      .map(
        (portfolios) => portfoliosRiskResults[Number(portfolios)].length === 0
      )
      .filter((checkedEmptiness) => checkedEmptiness === true);
    const isNoPortfoliosData =
      portfoliosKeys.length === emptyPortfoliosScan.length;
    setAreAllPortfoliosResultsEmpty(isNoPortfoliosData);

    setMergedRisk({
      ...portfoliosRiskResults,
      ...assetsRiskResults,
      ...benchmarkRiskResults,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPeriod,
    selectedBenchmarks,
    allPortfolios,
    initialApplication,
    selectedCustomDateRange,
    windowValue,
  ]);

  // const handleResetWindow = (): void => {
  //   setWindowValue(defaultWindowValue.default);
  // };

  useEffect(() => {
    handleDataFetching();
  }, [handleDataFetching]);

  Highcharts.setOptions({
    lang: {
      contextButtonTitle: t("chartExport.downloadImage"),
      downloadPDF: t("chartExport.downloadPDF"),
      downloadJPEG: t("chartExport.downloadJPEG"),
      downloadPNG: t("chartExport.downloadPNG"),
      decimalPoint: i18n.language === "en" ? "." : ",",
      thousandsSep: i18n.language === "en" ? "," : ".",
      resetZoom: t("rentability.resetZoom"),
    },
  });

  const yLabels = {
    formatter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      return `${Highcharts.numberFormat(this.value, 2)}%`;
    },
  };

  const xLabels = {
    startOnTick: true,
    formatter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-this-in-sfc
      const pointDate = this.value;

      try {
        const month = String(
          new Date(pointDate).toLocaleString(i18n.language, {
            month: "numeric",
          })
        );
        const year = new Date(pointDate).toLocaleString(i18n.language, {
          year: "2-digit",
        });
        return `${month}/${year}`;
      } catch {
        return `--`;
      }
    },
  };

  function tooltipFormatter(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/no-this-in-sfc
    return this.points.reduce(
      // eslint-disable-next-line func-names
      function (
        s: string,
        point: { series: { color: string; name: string }; y: number }
      ) {
        return `${s}<div style="display: flex; 
                justify-content: flex-start; 
                align-items: flex-start;
                padding-left: 8px;
                margin: 0;
                margin-bottom: 8px;
                color: rgba(2, 1, 1, 0.6);
                font-family: ${theme.fonts.primary};
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                max-width: 240px;
                ">
                <div style="background-color: ${point.series.color}; 
                            color: {series.color}; 
                            width: 5px; 
                            min-width: 5px;
                            max-width: 5px;
                            margin-right: 4px">
                            &nbsp;
                </div>
                <div style="font-weight: 600; min-width: 60px">
                  ${`${Highcharts.numberFormat(point.y, 2)}%`}
                </div>
                <div style="white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            ">
                  &nbsp;&nbsp;${point.series.name}
                </div>
            </div>`;
      },
      `    <div style="
            font-family: ${theme.fonts.primary};
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin: 8px;
            color: #333333;
          ">
        ${String(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-this-in-sfc
          new Date(this.x).toLocaleString(i18n.language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        )}
        </div>`
    );
  }

  const generateChartTitle = (): string => {
    if (!isEmpty(mergedRisk) && !isNil(mergedRisk)) {
      const firstRiskEntry = Object.keys(mergedRisk)[0];
      const firstRiskData = mergedRisk[firstRiskEntry];

      if (Object.keys(firstRiskData).length >= 1) {
        const firstDate = new Date(
          firstRiskData[0].date.replaceAll("-", "/")
        ).toLocaleDateString(i18n.language);
        const lastDate = new Date(
          last(firstRiskData)?.date.replaceAll("-", "/") || ""
        ).toLocaleDateString(i18n.language);

        return `${t("risk.title")} - ${firstDate} ${t(
          "rentability.to"
        )} ${lastDate}`;
      }
    }
    return "";
  };

  const handleShowAssets = (): void => {
    setShowAssets(!isAssetsShowing);
  };

  const getChart = (chart: HighchartsTypes.Chart): void =>
    setExposedChart(chart);

  const handleReflowChart = useCallback((): void => {
    try {
      if (typeof exposedChart !== "undefined") exposedChart.reflow();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [exposedChart]);

  useEffect(() => handleReflowChart, [containerWidth, handleReflowChart]);

  return (
    <SGridContainer key="RentabilityContainer" ref={containerRef}>
      {isLoadingRentability || isPortfolioRentabilityLoading ? (
        <SGridLoading key="RentabilityChartLoading">
          <SCircularProgress variant="indeterminate" color="inherit" />
        </SGridLoading>
      ) : (
        <>
          {!isNil(mergedRisk) &&
            !isEmpty(mergedRisk) &&
            !areAllPortfoliosResultsEmpty && (
              <>
                <SFloatingContainer>
                  <WindowSelector />
                </SFloatingContainer>
                <SFloatButton
                  onClick={handleShowAssets}
                  $isSelected={isAssetsShowing}
                >
                  <SFloatButtonIcon />
                  <SFloatButtonText>
                    {isAssetsShowing
                      ? t("rentability.hideAssets")
                      : t("rentability.showAssets")}
                  </SFloatButtonText>
                </SFloatButton>
              </>
            )}

          {/* No chart is shown if all portfolios results are empty */}
          {areAllPortfoliosResultsEmpty ? (
            <SGridLoading>
              <SNoResultsWarning>
                {t("risk.noResults.line1")} <br />
                {t("risk.noResults.line2alt")} <br />
                {t("risk.noResults.line3alt")}
                {/* The bellow commented code provides a window value reset action 
                    for the cases where the user might set its value to a non results state,
                    that will lead to the Window Value input not been reachable */}
                {/* {t("risk.noResults.line2.beginning")}
                <SLink
                  variant="button"
                  underline="none"
                  onClick={handleResetWindow}
                >
                  {t("risk.noResults.line2.resetWindow")}
                </SLink>
                {t("risk.noResults.line2.end")} <br />
                {t("risk.noResults.line3")} */}
              </SNoResultsWarning>
            </SGridLoading>
          ) : (
            <HighchartsProvider Highcharts={Highcharts} key="RiskChart">
              <HighchartsChart
                callback={getChart}
                exporting={getExportingConfig(
                  65,
                  80,
                  t("risk.tab"),
                  "",
                  theme.chartExportingButton.show,
                  {
                    enabled: true,
                    formattedAs: "percentage",
                    showLastValue: true,
                  }
                )}
                plotOptions={{
                  series: {
                    animation: false,
                  },
                }}
                key={`RiskChart-${i18n.language}`}
              >
                <Chart zoomType="x" height={550} />

                <Title
                  align="left"
                  verticalAlign="top"
                  useHTML
                  style={{
                    color: theme.colors.textDefault,
                    fontFamily: theme.fonts.primary,
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  {generateChartTitle()}
                </Title>

                {/* Subtitle is not smart enough not to render when chart is empty */}
                {mergedRisk && Object.keys(mergedRisk).length > 0 && (
                  <Subtitle
                    style={{
                      fontFamily: theme.fonts.primary,
                      fontSize: "12px",
                    }}
                  >
                    {t("rentability.zoomInfo")}
                  </Subtitle>
                )}

                <XAxis type="datetime" labels={xLabels} />

                <YAxis labels={yLabels}>
                  {mergedRisk &&
                    Object.keys(mergedRisk).map((rentabilityAssetKey) => {
                      return (
                        <React.Fragment key={`${rentabilityAssetKey}fragment`}>
                          {/* Plots Benchmarks Data */}
                          {selectedBenchmarks[rentabilityAssetKey] && (
                            <LineSeries
                              turboThreshold={20000}
                              connectNulls
                              marker={{ enabled: false }}
                              color={
                                generalAssetsList &&
                                generateBenchmarkColor(
                                  indexOf(
                                    rentabilityAssetKey,
                                    Object.keys(selectedBenchmarks)
                                  )
                                )
                              }
                              name={
                                selectedBenchmarks[rentabilityAssetKey].asset
                                  .label
                              }
                              key={rentabilityAssetKey}
                              data={mergedRisk[rentabilityAssetKey].map(
                                (values) => [
                                  Date.parse(values.date),
                                  chartNumber(values.value),
                                ]
                              )}
                              tickPixelInterval={72}
                            />
                          )}
                          {/* Plots Portfolios Data */}
                          {allPortfolios[Number(rentabilityAssetKey)] && (
                            <LineSeries
                              turboThreshold={20000}
                              connectNulls
                              marker={{ enabled: false }}
                              color={generateAssetColor(
                                Number(rentabilityAssetKey)
                              )}
                              name={
                                allPortfolios[Number(rentabilityAssetKey)].name
                              }
                              key={`${rentabilityAssetKey}--`}
                              data={mergedRisk[Number(rentabilityAssetKey)].map(
                                (values) => [
                                  Date.parse(values.date),
                                  chartNumber(values.value),
                                ]
                              )}
                              tickPixelInterval={72}
                            />
                          )}
                          {/* Plots Assets Data */}
                          {isAssetsShowing &&
                            generalAssetsList &&
                            generalAssetsList[rentabilityAssetKey] && (
                              <LineSeries
                                turboThreshold={20000}
                                connectNulls
                                marker={{ enabled: false }}
                                color={generateAssetColor(
                                  indexOf(
                                    rentabilityAssetKey,
                                    Object.keys(generalAssetsList)
                                  ) + Object.keys(allPortfolios).length
                                )}
                                name={String(
                                  generalAssetsList[rentabilityAssetKey].asset
                                    .label || "error"
                                )}
                                key={`${rentabilityAssetKey}--asset`}
                                data={mergedRisk[rentabilityAssetKey].map(
                                  (values) => [
                                    Date.parse(values.date),
                                    chartNumber(values.value),
                                  ]
                                )}
                                tickPixelInterval={72}
                              />
                            )}
                        </React.Fragment>
                      );
                    })}
                </YAxis>

                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                />

                <Tooltip
                  shared
                  useHTML
                  outside
                  borderRadius={4}
                  borderColor="rgba(51, 51, 51, 0.5)"
                  // eslint-disable-next-line react/jsx-no-bind
                  formatter={tooltipFormatter}
                  backgroundColor="rgba(255, 255, 255, 0.9)"
                  style={{ zIndex: 9999 }}
                />
              </HighchartsChart>
            </HighchartsProvider>
          )}
        </>
      )}
    </SGridContainer>
  );
};
